import themeGet from "@styled-system/theme-get"
import styled from "styled-components"

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 100%;
    max-width: 650px;
  }
`;

export const TableWrapper = styled.div`
  background: linear-gradient(180deg, rgba(91,109,216,1) 0%, rgba(209,72,124,1) 100%);
  color: white;
  

  overflow-y: auto; 
  max-height: calc(100vh - 140px);

  display: flex;
  flex-direction: column;

  max-width: 660px;

  gap: 26px;
  @media only screen and (min-width: 410px) {
    gap: 28px;
    max-height: calc(100vh - 170px);
  }
  @media only screen and (min-width: 650px) {
    gap: 30px;
  }

  width: calc(100vw - 70px);
  font-family: Heebo, sans-serif;
    padding: 20px 14px 20px 14px;
  
`


export const TableTextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media only screen and (min-width: 410px) {
    gap: 24px;
  }
  @media only screen and (min-width: 650px) {
    gap: 30px;
  }
`

export const TableTitle = styled.h2`
    font-size: 20px;
    @media only screen and (min-width: 410px) {
      font-size: 24px;
    }
    @media only screen and (min-width: 650px) {
      font-size: 28px;
    }
    margin: 0;
`

export const TableKVBox = styled.div`
  display: flex;
  flex-direction: column;
  max-height: fit-content;
  gap: 12px;
  @media only screen and (min-width: 410px) {
      gap: 13px;
  }
  @media only screen and (min-width: 650px) {
    gap: 15px;
  }
`

export const TableKV = styled.h3`
    font-size: 14px;
    @media only screen and (min-width: 410px) {
      font-size: 16px;
    }
    @media only screen and (min-width: 650px) {
      font-size: 17px;
    }
    margin: 0px;
    font-weight: normal;
`

export const TradingLeagueTable = styled.table`
  background: #5b6dd8;
  border: 1px solid white;
  border-collapse: collapse;
  text-align: left;
  box-sizing: border-box;
  
  border-radius: 6px ;
`

export const TableCaption = styled.caption`
  background: #5b6dd8;
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
  padding: 6px 10px;
  border-radius: 6px 6px 0px 0px;
  font-size: 13px;
  font-weight: 600;
  @media only screen and (min-width: 410px) {
    font-size: 15px;
    padding: 7px 10px;
  }
  @media only screen and (min-width: 650px) {
    font-size: 17px;
    padding: 8px 10px;
  }
`

export const TableTr = styled.tr`
`

export const TableTh = styled.th`
  border: 1px solid white;
  padding: 6px 10px;
  font-size: 12px;
  
  @media only screen and (min-width: 410px) {
    font-size: 14px;
    padding: 7px 10px;
  }
  @media only screen and (min-width: 650px) {
    font-size: 16px;
    padding: 8px 10px;
  }
  font-weight: 500;
`

export const TableTd = styled.td`
  height: 30px;
  border-left: 1px;
  border-right: 0px;
  border-top: 0px;
  border-bottom: 0px;
  border-color: white;
  border-style: solid;
  padding: 6px 10px;
  font-size: 11px;
  @media only screen and (min-width: 410px) {
    font-size: 13px;
    padding: 7px 10px;
  }
  @media only screen and (min-width: 650px) {
    font-size: 15px;
    padding: 8px 10px;
  }
`

export const ImageNameTd = styled.td`
  display: flex;
  align-items: center;
  height: 30px;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-bottom: 0px;
  border-color: white;
  border-style: solid;
  font-size: 11px;
  padding: 6px 10px;
  gap: 6px;
  @media only screen and (min-width: 410px) {
    font-size: 13px;
    padding: 7px 10px;
    gap: 7px;
  }
  @media only screen and (min-width: 650px) {
    font-size: 15px;
    padding: 8px 10px;
    gap: 8px;
  }
`


export const TwitterLink = styled.a`
  color: white;
  &:hover {
    text-decoration: underline;
  }
`

export const TableImage = styled.img`
  height: 16px;
  width: 16px;
  border-radius: 100px;
  @media only screen and (min-width: 410px) {
    height: 18px;
    width: 18px;
  }
  @media only screen and (min-width: 650px) {
    height: 20px;
    width: 20px;
  }
`


export const RecentWinnersBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  @media only screen and (min-width: 410px) {
    gap: 18px;
  }
  @media only screen and (min-width: 650px) {
    gap: 22px;
  }
`

export const RecentWinerrsTextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  @media only screen and (min-width: 410px) {
    gap: 18px;
  }
  @media only screen and (min-width: 650px) {
    gap: 22px;
  }
`










export const SectionWrapper = styled.section`
  width: 100%;
  overflow: hidden;

  
  display: flex;
  align-items: center;
  justify-content: center;
  
`

export const CardsBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 650px;
  padding: 80px 18px 80px 18px;
  gap: 36px;
  color: white;
  @media only screen and (min-width: 800px) {
    flex-direction: row;
  width: 800px;
  padding: 120px 44px 120px 44px;
  gap: 18px;
  max-width: 800px;

  }
  @media only screen and (min-width: 990px) {
    flex-direction: row;
  width: 990px;
  gap: 30px;
  max-width: 990px;

  }
  @media only screen and (min-width: 1220px) {
    width: 1220px;
  gap: 50px;
  max-width: 1220px;
  }
`

export const CardWrapperLeaderBoard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding: 20px 16px ;
  background-image: url(/for_leaderboard.jpg);
  aspect-ratio: 1340 / 950;
  background-size: cover;
  border-radius: 12px;
  @media only screen and (min-width: 410px) {
    gap: 10px;
    padding: 24px 20px ;
  }
  @media only screen and (min-width: 650px) {
    gap: 12px;
    padding: 24px 20px ;
  }
  @media only screen and (min-width: 800px) {
    width: 50%;
    gap: 8px;
    padding: 20px 16px ;
  }
  @media only screen and (min-width: 990px) {
    gap: 10px;
    padding: 24px 20px ;
  }
  @media only screen and (min-width: 1220px) {
    gap: 12px;
    padding: 24px 20px ;
  }
`
export const CardWrapperRecentWinners = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding: 20px 16px ;
  background-image: url(/for_recent_winners.jpg);
  aspect-ratio: 1340 / 950;
  background-size: cover;
  border-radius: 12px;
  @media only screen and (min-width: 410px) {
    gap: 10px;
    padding: 24px 20px ;
  }
  @media only screen and (min-width: 650px) {
    gap: 12px;
    padding: 24px 20px ;
  }
  @media only screen and (min-width: 800px) {
    width: 50%;
    gap: 8px;
    padding: 20px 16px ;
  }
  @media only screen and (min-width: 990px) {
    gap: 10px;
    padding: 24px 20px ;
  }
  @media only screen and (min-width: 1220px) {
    gap: 12px;
    padding: 24px 20px ;
  }
`

export const CardHeading = styled.h3`
  font-weight: 500;
  font-size: 26px;
  margin: 0;
  @media only screen and (min-width: 410px) {
    font-size: 28px;
  }
  @media only screen and (min-width: 650px) {
    font-size: 32px;
  }
  @media only screen and (min-width: 800px) {
    font-size: 26px;
  }
  @media only screen and (min-width: 990px) {
    font-size: 28px;
  }
  @media only screen and (min-width: 1220px) {
    font-size: 32px;
  }
`

export const CardParagraph = styled.p`
  font-weight: 400;
  font-size: 15px;
  margin: 0;
  @media only screen and (min-width: 410px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 650px) {
    font-size: 17px;
  }
  @media only screen and (min-width: 800px) {
    font-size: 15px;
  }
  @media only screen and (min-width: 990px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 1220px) {
    font-size: 17px;
  }
`

export const CardButton = styled.button`
  width: 100%;
  background-color: #221b2c;
  color: white;
  font-size: 11px;
  border: 2px solid white;
  font-weight: 300;
  border-radius: 100px;
  height: 32px;
  margin: 0;
  margin-top: 11px;
  @media only screen and (min-width: 410px) {
    height: 36px;
    font-size: 12px;
    margin-top: 12px;
  }
  @media only screen and (min-width: 650px) {
    height: 40px;
    font-size: 13px;
    margin-top: 14px;
  }
  @media only screen and (min-width: 800px) {
    height: 32px;
  font-size: 11px;
  margin-top: 11px;
  }
  @media only screen and (min-width: 990px) {
    height: 36px;
    font-size: 12px;
    margin-top: 12px;
  }
  @media only screen and (min-width: 1220px) {
    height: 40px;
    font-size: 13px;
    margin-top: 14px;
  }

  &:hover{
    transform: scale(1.02);
    transition-duration: 200ms;
    cursor: pointer;
  }
`



export const BannerWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 40px;
  min-height: 802px;
  overflow: hidden;
  position: relative;
  background-image: -moz-linear-gradient(
    280deg,
    rgb(100, 43, 115) 0%,
    rgb(198, 66, 110) 100%
  );
  background-image: -webkit-linear-gradient(
    280deg,
    rgb(13, 125, 248) 15%,
    rgb(157, 69, 167) 50%,
    rgb(255, 31, 112) 85%
  );
  background-image: -ms-linear-gradient(
    280deg,
    rgb(13, 125, 248) 15%,
    rgb(157, 69, 167) 50%,
    rgb(255, 31, 112) 85%
  );
  @media only screen and (min-width: 1201px) and (max-width: 1440px) {
    min-height: 100vh;
    // padding: 20px 0 -50px;
  }
  @media only screen and (max-width: 1099px) {
    min-height: 100%;
  }
  @media only screen and (max-width: 480px) {
    padding-top: 90px;
  }
  > div.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: calc(802px - 100px);
    @media only screen and (min-width: 1201px) and (max-width: 1440px) {
      min-height: calc(100px - 50px);
      margin-top: 35px;
      align-items: flex-start;
      justify-content: flex-start;
    }
    @media only screen and (max-width: 1099px) {
      min-height: 100%;
    }
    @media only screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }
  .bannerBottomShape {
    position: absolute;
    right: 0;
    bottom: -2px;
    @media only screen and (max-width: 999px) {
      display: none;
    }
  }
`;

export const BannerContent = styled.div`
  width: 60%;
  flex-shrink: 0;
  @media only screen and (max-width: 1199px) {
    flex-shrink: 0;
    width: 100%;
    margin-top: 25px;
  }
  @media only screen and (min-width: 1366px) {
    width: 94%;
    line-height: 42px;
    margin-bottom: 20px;
    // padding-top: 20px;
  }


  h1 {
    font-size: 28px;
    line-height: 42px;
    font-weight: 700;
    color: ${themeGet('colors.menu', '#0D233E')};
    margin-bottom: 24px;r
    @media only screen and (min-width: 480px) and (max-width: 1366px) {
      font-size: 46px;
      // margin: 30px 0;
    }  
    @media only screen and (min-width: 1366px) {
      font-size: 60px;
      margin: 40px 0;
    }  
  }

  p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    // font-weight: 400;
    line-height: 29px;
    @media only screen and (min-width: 1366px) {
      width: 80%;
      font-size: 28px;
      line-height: 36px;
      margin-top: 60px;
    }
  }
`;












export const ParticipateBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 650px;
  padding: 36px 18px 0px 18px;
  gap: 26px;
  @media only screen and (min-width: 410px) {
    gap: 30px;
  }
  @media only screen and (min-width: 650px) {
    gap: 34px;
  }
  @media only screen and (min-width: 800px) {
    width: 900px;
    max-width: 700px;
  }
  @media only screen and (min-width: 990px) {
  padding: 50px 18px 0px 18px;
    gap: 40px;
    width: 920px;
    max-width: 920px;
  }
  @media only screen and (min-width: 1220px) {
    width: 1220px;
    max-width: 1170px;
  }
`

export const HowToParticipateHeading = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  @media only screen and (min-width: 410px) {
    font-size: 35px;
  }
  @media only screen and (min-width: 650px) {
    font-size: 38px;
  }
  @media only screen and (min-width: 800px) {
    font-size: 38px;
  }
`

export const ParticipateCardBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 46px;
  @media only screen and (min-width: 410px) {
    gap: 50px;
  }
  @media only screen and (min-width: 650px) {
    gap: 55px;
  }
  @media only screen and (min-width: 800px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 55px;
  }
`

export const ParticipateCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  @media only screen and (min-width: 410px) {
    gap: 13px;
  }
  @media only screen and (min-width: 650px) {
    gap: 15px;
  }
  @media only screen and (min-width: 800px) {
    font-size: 26px;
  }
`

export const ParticipateCardImg = styled.img`
  height: 60px;
  cursor: pointer;
  @media only screen and (min-width: 410px) {
    height: 70px;
  }
  @media only screen and (min-width: 650px) {
    height: 90px;
  }
  @media only screen and (min-width: 800px) {
    height: 80px;
  }
`

export const ParticipateCardText = styled.p`
  margin: 0;
  color: black;
  text-align: center;
  font-size: 16px;
  @media only screen and (min-width: 410px) {
    font-size: 19px;
  }
  @media only screen and (min-width: 650px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 800px) {
    font-size: 18px;
  }
`

export const ParticipateCardLink = styled.a`
  margin: 0;
  color: black;
  text-decoration: underline;
  &:hover {
    font-weight: 500;
  text-decoration: underline;
  }
`
