
import { CardButton, BannerContent, BannerWrapper, CardHeading, CardParagraph, SectionWrapper, CardWrapperLeaderBoard, CardWrapperRecentWinners, CardsBox, HowToParticipateHeading, ImageNameTd, ParticipateBox, ParticipateCard, ParticipateCardBox, ParticipateCardImg, ParticipateCardLink, ParticipateCardText, RecentWinerrsTextBox, RecentWinnersBox, TableCaption, TableImage, TableKV, TableKVBox, TableTd, TableTextBox, TableTh, TableTitle, TableTr, TableWrapper, TradingLeagueTable, TwitterLink, ImageWrapper } from './style';
import React from 'react';
import Fade from 'react-reveal/Fade';
import { openModal, closeModal } from '@redq/reuse-modal';
import Text from 'common/components/Text';
import Button from 'common/components/Button';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import { openUrl } from '../../../utils/misc';

import tableData from '../../../../TABLEDATE.json'


/** The table that shows leader board of the current trading league season.
 *  It gets its data from `TABLEDATA.json` file on the main folder. */
export const TableLeaderBoard = () => (
    (
        <TableWrapper>
          <TableTextBox>          
            <TableTitle>
              SEASON {tableData.at(-1).season} TRADING LEAGUE
            </TableTitle>
            <TableKVBox>
              <TableKV>Current League: Season {tableData.at(-1).season}</TableKV>
              <TableKV>No of participants: {tableData.at(-1).participantsCount}</TableKV>  
              <TableKV>Duration: {table.duration}</TableKV>
            </TableKVBox>
          </TableTextBox>
          <TradingLeagueTable>
            
            <TableCaption>Season {tableData.at(-1).season} Top Traders</TableCaption>
            <tr>
              <TableTh>Member</TableTh>
              <TableTh>Return</TableTh>
              <TableTh>Twitter</TableTh>
            </tr>
                      {tableData.at(-1).traders.map(trader => (
                        <TableTr>
                          <ImageNameTd>
                            <TableImage src={`/${trader.image}`}/>
                            {trader.member}
                          </ImageNameTd>
                          
                          <TableTd style={{textAlign: 'left'}}>{trader.return}</TableTd>
                          <TableTd>
                            <TwitterLink target='_blank' href={`https://twitter.com/${trader.twitter.slice(1)}`}>
                              {trader.twitter}
                            </TwitterLink>
                          </TableTd>
                        </TableTr>
                      ))}
          </TradingLeagueTable>
        </TableWrapper>
      )
)


/** The table that shows recent winners board of all the trading league seasons.
 *  It gets its data from `TABLEDATA.json` file on the main folder. */
export const TableRecentWinners = () => (
    (
        <TableWrapper>
                    
            <TableTitle>
                RECENT WINNERS FOR EACH SEASON
            </TableTitle>
          {tableData.map(table => (
            <RecentWinnersBox>
                    <RecentWinerrsTextBox>  
                        <TableKVBox>
                        <TableKV>League: Season {table.season}</TableKV>
                        <TableKV>No of participants: {table.participantsCount}</TableKV>
                        <TableKV>Duration: {table.duration}</TableKV>
                        </TableKVBox>
                    </RecentWinerrsTextBox>
                    <TradingLeagueTable>
                        
                        <TableCaption>Season {table.season} Top Traders</TableCaption>
                        <tr>
                        <TableTh>Member</TableTh>
                        <TableTh>Return</TableTh>
                        <TableTh>Twitter</TableTh>
                        </tr>
                                {table.traders.slice(0, 3).map(trader => (
                                    <TableTr>
                                    <ImageNameTd>
                                        <TableImage src={`/${trader.image}`}/>
                                        {trader.member}
                                    </ImageNameTd>
                                    
                                    <TableTd style={{textAlign: 'left'}}>{trader.return}</TableTd>
                                    <TableTd>
                                        <TwitterLink target='_blank' href={`https://twitter.com/${trader.twitter.slice(1)}`}>
                                        {trader.twitter}
                                        </TwitterLink>
                                    </TableTd>
                                    </TableTr>
                                ))}
                    </TradingLeagueTable>
            </RecentWinnersBox>
          ))}
        </TableWrapper>
      )
)


export const CardsSection = () => (
  <SectionWrapper>
    <CardsBox>
      <CardLeaderBoard/>
      <CardRecentWinners/>
    </CardsBox>
  </SectionWrapper>
)

export const CardLeaderBoard = () => (
  <CardWrapperLeaderBoard>
    <CardHeading>Leaderboard</CardHeading>
    <CardParagraph> Registration for The Trading League for Season 3 will commence soon. </CardParagraph>
    <CardButton disabled={true} onClick={handleImgModal}>VIEW CURRENT LEADERBOARD</CardButton>
  </CardWrapperLeaderBoard>
)

export const CardRecentWinners = () => (
  <CardWrapperRecentWinners>
    <CardHeading>Recent Winners</CardHeading>
    <CardParagraph>Each league produces the best 3 <br/> winners showing their rewards and all.</CardParagraph>
    <CardButton onClick={handleTableRecentWinnersModal}>CHECKOUT CURRENT WINNERS</CardButton>
  </CardWrapperRecentWinners>
)



export const ParticipateSection = () => (
  <SectionWrapper>
    <ParticipateBox>
      <HowToParticipateHeading>How to participate?</HowToParticipateHeading>
      <ParticipateCardBox>
        <ParticipateCard>
          <ParticipateCardImg src='/discord.png' onClick={()=>openUrl('https://discord.gg/8Fq3Z568tp')}/>
          <ParticipateCardText>
            Navigate to the <br/> SamoDAO <ParticipateCardLink href='https://discord.gg/samoyedcoin' target='_blank'>Discord</ParticipateCardLink>. 
          </ParticipateCardText>
        </ParticipateCard>
        <ParticipateCard>
          <ParticipateCardImg src='/info.png' onClick={()=>openUrl('https://discord.gg/8Fq3Z568tp')}/>
          <ParticipateCardText>
            Check out the <br/> #tl-info-channel.
          </ParticipateCardText>
        </ParticipateCard>
        <ParticipateCard>
          <ParticipateCardImg src='/win.png' onClick={()=>openUrl('https://discord.gg/8Fq3Z568tp')}/>
          <ParticipateCardText>
            Sign up and get <br/> ready to compete!
          </ParticipateCardText>
        </ParticipateCard>
      </ParticipateCardBox>
    </ParticipateBox>
  </SectionWrapper>
)





export const TradingLeagueBanner = () => {

  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          <Fade up delay={100}>
            <Heading
              as="h1"
              content="SAMO DAO TRADING LEAGUE  "
            />
          </Fade>
          <Fade up delay={200}>
            <Text
              content="Think you have what it takes to be one of SamoDAO's top traders?
               Want to be surrounded by other passionate traders seeking to navigate the markets?
               Looking to put your skills to the test and earn some cash?"
            />
            <Text
            content="Sign-up for the SamoDAO Trading League, a 8-week long competition where community members are put to the ultimate test of trading crypto markets."
            />
          </Fade>
        </BannerContent>
      </Container>
    </BannerWrapper>
  );
};



const handleTableLeaderBoardModal = () => {
  openModal({
    config: {
      className: 'video-modal',
      disableDragging: true,
      default: {
        width: 'auto',
        height: 'auto',
        x: 0,
        y: 0,
      },
    },
    component: TableLeaderBoard,
    componentProps: {},
    closeComponent: CloseModalButton,
    closeOnClickOutside: true,
  });
};





const handleImgModal = () => {
  openModal({
    config: {
      className: 'video-modal',
      disableDragging: true,
      default: {
        width: 'auto',
        height: 'auto',
        x: 0,
        y: 0,
      },
    },
    component: () => (
      <ImageWrapper>
        <img
          title="How to Stake on Solana"
          src='/leaderboard.png'
          alt='How to Stake'
          frameBorder="0"
        />
      </ImageWrapper>
    ),
    componentProps: {},
    closeComponent: CloseModalButton,
    closeOnClickOutside: true,
  });
};

const handleTableRecentWinnersModal = () => {
  openModal({
    config: {
      className: 'video-modal',
      disableDragging: true,
      default: {
        width: 'auto',
        height: 'auto',
        x: 0,
        y: 0,
      },
    },
    component: TableRecentWinners,
    componentProps: {},
    closeComponent: CloseModalButton,
    closeOnClickOutside: true,
  });
};

const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);
